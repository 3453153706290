.main11 {
    float: left;
    width: 1184px;

}

.main12 {
    float: left;
    width: 296px;
}

.sis-tab-sem-menu {
    position: relative;
}

.billing_table {
    width: px;
    /* width: 70%; */
    padding: 28px 20px 28px 20px;
    gap: 28px;
    /* background: rgb(196, 87, 87); */
}

.Semester_Registration_fees {
    font-family: "Roboto";
    font-size: "14px";
    font-Weight: "600";
    text-align: "left";
    color: "rgba(142, 142, 142, 1)";
}

.bill_btn {
    width: 106px;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    color: #FFFFFF;
    background-color: brown;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin-top: 35px;
}
.genrate-billing-row-main h4{
    font-size: 12px;
}
.billing_detail {
    width:100%;
    padding: 10px 20px 10px 20px;
    gap: 10px;
    border: 0px 0px 0px 1px;
    border: 1px solid lightgray;
    height: 1067px;
    color: #4B4B4B;
}
.billing_detail input, .billing_detail .ant-select-selection-item{
    font-size: 10px !important;
    border-radius: 5px;
}
.add-billing-plan {
    position: absolute;
    top: -24px;
    left: 158px;
    font-size: 10px;
    padding: 1px 10px;
    /* line-height: 64px; */
    height: 18px;
}
.billing-input-row > div{
    padding: 3px !important;
}
.billing-input {
    font-size: 12px;
    padding: 0px 2px;
    height: 23px;
    margin: 0px;
    line-height: 23px;
    width: 100%;
    text-align: center;
}
.billing_font {
    font-family: roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
}

.fall {
    margin-top: 31px;
    width: 150px;
    display: flex;
    justify-content: space-between;
    font-weight: 400;
}

.Total_Due {
    margin-top: 40px;
    font-family: roboto;
    font-weight: 400;
}

.billing_row {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

}

.Publish_Bill_button {
    width: 106px;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    color: #FFFFFF;
    background-color: brown;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-top: 25px;
}

.biling_detail>div:nth-child(2) span:nth-child(2) {
    color: darkblue;
}

.payment_plan{
    width: 156px;
    height: 26px;
    padding: 3px 4px 3px 4px;
    border-radius: 4px 4px 4px 4px;
    background-color: rgba(182, 65, 65, 1);
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    position: relative;
    bottom: 22px;
    left: 160px;
}
.input_payment_plan{
    width: 360px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid  rgba(246, 76, 76, 1);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}




