/* Assuming '.ant-input' class is applied to all dropdowns */
.ant-input {
    width: 100%; /* Example width */
    border: 1px solid #d9d9d9; /* Example border color */
    border-radius: 4px; /* Example border radius */
    padding: 8px; 
    box-sizing: none;
    color: #000;
  }
  
  .css-dev-only-do-not-override-djtmh8 .ant-select-arrow {
    color: #1890ff;
  }
  