
.online-form-step-box {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  
  .online-form-step {
    position: relative;
    text-align: center;
    width: 100px;
  }
  
  .online-form-step-line {
    position: absolute;
    width: 65px;
    height: 1px;
    display: block;
    background-color: #ccc;
    left: 76px;
    top: 31%;
    transform: translateY(-50%);
  }
  
  .online-form-step h3 {
    color: #282c34;
    font-weight: bold;
    font-size: 12px;
  }
  
  .online-form-step span {
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: #f0f0f0;
    color: #000;
    display: inline-block;
    border-radius: 50px;
    overflow: hidden;
    padding: 15px;
    position: relative;
    z-index: 10;
    font-weight: bold;
  }
  
  .online-form-step.running span {
    cursor: default;
    color: #000;
    background-color: yellow;
  }
  
  .online-form-step.running h3 {
    color: #000;
  }
  
  .online-form-step.complete span {
    cursor: pointer;
    color: #fff;
    background-color: green;
  }
  
  .online-form-step.complete h3 {
    color: green;
  }
  
  .online-form-step.complete .form-step-line {
    background-color: green;
  }