 .Academic_section {
     width: 1400px;
     height: 60px;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
 }

 .student_type_info {
     width: 1010px;
     height: 70px;
     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
 }

 .student_type_inf {
     display: flex;
     justify-content: row;
     gap: 40px;
 }

 .student_type_inf1 {
     display: flex;
     justify-content: row;
     font-weight: bold;
     gap: 40px;
 }

 .applic-da {
     position: relative;
     margin-left: 95px;
 }

 .sem-date-wr {
     position: relative;
     margin-left: 97px;
 }

 .sem-date {
     position: relative;
     margin-left: 127px;
 }

 .Line {
     border: 1px solid rgba(238, 238, 238, 1);
 }

 .search-box {
     width: 1200px;
     height: 50px;
 }

 .ishant {
     display: flex;
     position: relative;
     bottom: 48px;
     gap: 103px;
 }

 .name_hed {
     font-weight: 500;
     color: rgba(75, 75, 75, 1);
     font-size: 24px;
     position: relative;
     left: 69px;
     bottom: 10px;
 }

 .ishnat-dev {
     display: flex;
     position: relative;
     left: 243px;
     gap: 162px;
     bottom: 59px;
 }

 .img_P {
     width: 50px;
     height: 50px;
     border-radius: 4px
 }

 .div-S {
     position: relative;
     right: 27px;
 }

 .div-e {
     position: relative;
     right: 20px;
 }

 .search-head {
     font-size: 16px;
     font-weight: 500;
     line-height: 28px;
     color: rgba(75, 75, 75, 1);
     position: relative;
     top: 11px;
 }

 .search-tag {
     width: 190px;
     height: 40px;
     border-radius: 4px;
     position: relative;
     left: 127px;
     bottom: 24px;
     border: 1px bold rgba(225, 225, 225, 1);
 }

 .search-icon {
     width: 37.33px;
     height: 21.33px;
     color: rgba(142, 142, 142, 1);
     position: relative;
     bottom: 18px;
     left: 89px;
 }

 .univer-info {
     font-weight: bold;
     color: rgba(59, 130, 246, 1);
     font-size: 14px;
 }

 .course-type {
     position: relative;
     top: 30px;
 }

 .table-print {
     width: 1260px;
     height: 336px;
     position: relative;
     top: 15px;
 }