@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
body {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
  /* font-family: "Open Sans", sans-serif; */
  font-family: "Roboto", serif;
  overflow-x: hidden;
}
p,h1,h2,h3,h4,h5,h6,ul,li{
  margin: 0px;
  padding: 0px;
  color:rgb(37, 37, 37);
}
.input-box{
  display: block;
  margin: 10px 0px;
}
.input-box label {
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 8px;
  display: inline-block;
  color: #626262;
}
.theme-content-head{
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  margin-top: 15px;
  align-items: center;
}
.theme-content-left-head{
  flex: 1;
}
.table-status-activate {
  background-color: rgb(4, 168, 4);
  color: rgb(255, 255, 255);
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 3px;
}
.table-status-deactivate {
  background-color: rgb(158, 52, 3);
  color: rgb(255, 255, 255);
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 3px;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.row{
  display: block;
  margin: 0px -10px;
}
.row::after {
  content: "";
  clear: both;
  display: table;
}
.custom-check-box p{
  cursor: pointer;
}
[class*="col-"] {
  float: left;
  padding:0px  10px;
}
/* For desktop: */
.col-1 {width: 8.33%;}
.col-2 {width: 16.66%;}
.col-3 {width: 25%;}
.col-4 {width: 33.33%;}
.col-5 {width: 41.66%;}
.col-6 {width: 50%;}
.col-7 {width: 58.33%;}
.col-8 {width: 66.66%;}
.col-9 {width: 75%;}
.col-10 {width: 83.33%;}
.col-11 {width: 91.66%;}
.col-12 {width: 100%;}
.student-profile-information {
  margin-top: 5px;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
}