/* Basic Information */
.basic-information{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: top;
    flex-wrap: wrap;
}

/* Program Registration */
.program-registration{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.program-registration .input-box{
    margin-left: 10px;
    margin-right: 10px;
}